<template>
  <div class="password-requirements">
    <div class="message">
      <strong>Your password must include:</strong>
    </div>
    <div class="password-requirement">
      <CheckCircleIcon :class="{ 'is-valid': isAtLeast6Characters }" />
      <span>At least 6 characters</span>
    </div>
    <div class="password-requirement">
      <CheckCircleIcon :class="{ 'is-valid': hasAtLeastOneLowercaseLetter }" />
      <span>At least one lowercase letter</span>
    </div>
    <div class="password-requirement">
      <CheckCircleIcon :class="{ 'is-valid': hasAtLeastOneUppercaseLetter }" />
      <span>At least one uppercase letter</span>
    </div>
    <div class="password-requirement">
      <CheckCircleIcon :class="{ 'is-valid': hasAtLeastOneNumberOrSpecialSymbol }" />
      <span>At least one number or special symbol</span>
    </div>
  </div>
</template>

<script>

  import CheckCircleIcon from '@/assets/icons/check-circle.svg'

  export default {
    components: {
      CheckCircleIcon,
    },
    props: {
      password: {
        type: String,
        default: null,
      },
    },
    computed: {
      isAtLeast6Characters() {
        return this.password.length >= 6
      },
      hasAtLeastOneLowercaseLetter() {
        return /[a-z]+/.test(this.password)
      },
      hasAtLeastOneUppercaseLetter() {
        return /[A-Z]+/.test(this.password)
      },
      hasAtLeastOneNumberOrSpecialSymbol() {
        return /[^a-z]+/i.test(this.password)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .password-requirements
    @apply mb-6
    @apply pl-6
    @apply text-left

    .message
      @apply mb-3

    .password-requirement
      @apply flex
      @apply items-center

      svg
        @apply w-4
        @apply h-4
        @apply mr-2
        @apply text-gray-600

        min-width: theme('width.4')
        min-height: theme('height.4')

        &.is-valid
          @apply text-success-500

</style>
