import { useStore } from 'vuex'
import { ref, computed, onMounted } from 'vue'

export default (props) => {

  const store = useStore()

  // make sure the form and field exists first!
  if (!store.state.forms[props.formName]) {
    throw new Error(`Invalid formName specified: form "${props.formName}" does not exist`)
  }

  // $refs
  const focusElement = ref(null)
  const containerElement = ref(null)

  // computed
  const form = computed(() => {
    return store.state.forms[props.formName]
  })

  const isFormValid = computed(() => {
    return store.getters['forms/isFormValid'](props.formName)
  })

  // methods
  const scrollToFormTop = (options = { behavior: 'smooth' }) => {
    if (containerElement.value) {
      containerElement.value.scrollIntoView({ behavior: 'smooth' })
    }
  }

  // @NOTE: this doesn't need to be reactive because we are basically just
  //  grabbing the values from form.template and saving them so we can reset the
  //  form below and still know what the template values were
  const templateValues = Object.assign({}, form.value.template || {})

  const applyTemplateValues = () => {

    let hadTemplateValuesApplied = false

    Object.keys(templateValues).forEach((fieldName) => {
      const newValue = templateValues[fieldName]
      hadTemplateValuesApplied ||= !Array.isArray(newValue)
        ? newValue !== '' && newValue !== null
        : newValue.length !== 0
      store.commit('forms/SET_FIELD_VALUE', { formName: props.formName, fieldName, newValue })
    })

    return hadTemplateValuesApplied

  }

  onMounted(() => {
    if (focusElement.value) {
      focusElement.value.focus()
    }
  })

  if (!props.skipReset) store.commit('forms/RESET_FORM', props.formName)

  return {
    form,
    isFormValid,
    focusElement,
    templateValues,
    scrollToFormTop,
    containerElement,
    applyTemplateValues,
  }

}
