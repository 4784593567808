<template>

  <form @submit="submit">
    <h4>{{ title }}</h4>
    <FormSubmitErrors :formName="formName" />
    <div>
      <FormInput :formName="formName" fieldName="password" ref="focusElement" />
      <PasswordRequirements :password="form.fields.password.value" />
      <FormInput :formName="formName" fieldName="passwordConfirmation" />
    </div>
    <button type="submit" :disabled="form.isLoading || !isFormValid">{{ buttonTitle }}</button>
  </form>

  <LoadingOverlay v-if="form.isLoading" />

</template>

<script>

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import FormSubmitErrors from '@/components/forms/FormSubmitErrors.vue'
  import PasswordRequirements from '@/components/forms/PasswordRequirements.vue'

  export default {
    components: {
      FormInput,
      LoadingOverlay,
      FormSubmitErrors,
      PasswordRequirements,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      buttonTitle: {
        type: String,
        required: true,
      },
      formName: {
        type: String,
        required: true,
      },
      submit: {
        type: Function,
        default: () => {},
      },
    },
    setup(props) {

      // composables
      const { form, isFormValid, focusElement } = useForm({ formName: props.formName })

      return {
        form,
        isFormValid,
        focusElement,
      }

    },
  }

</script>

<style lang="stylus" scoped>

  button
    @apply w-full

</style>
