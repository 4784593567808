<template>
  <div class="page-container">
    <img class="logo" src="@/assets/images/esprezzo-logo.png" />
    <div ref="containerElement" class="container">
      <LoadingOverlay v-if="form.isLoading" />
      <PasswordForm
        :submit="submit"
        :form-name="formName"
        button-title="Set password"
        :title="`Set password for @${user.username}`"
        />
    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'
  import useBeforeUnload from '@/composables/useBeforeUnload'

  import PasswordForm from '@/components/forms/PasswordForm.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'

  export default {
    components: {
      PasswordForm,
      LoadingOverlay,
    },
    setup() {

      // data
      const formName = 'setPasswordForm'

      // composables
      const { form, scrollToFormTop, containerElement } = useForm({ formName })

      const { removeBeforeUnloadListener } = useBeforeUnload({ message: 'Are you sure you want to leave this page without setting a password?' })

      return {
        form,
        formName,
        scrollToFormTop,
        containerElement,
        removeBeforeUnloadListener,
      }

    },
    computed: {
      ...mapState('user', ['user']),
    },
    methods: {
      submit($event) {
        if ($event) $event.preventDefault()

        this.$store.dispatch('forms/SUBMIT_FORM', this.formName)
          .then((result) => {
            this.removeBeforeUnloadListener()
            this.$router.push('/dashboard')
          })
          .catch(() => {
            this.scrollToFormTop()
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/logged-out-page.styl'

</style>
